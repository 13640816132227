import React, { useEffect, useState } from "react"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import { useGetStoneTypeCountQuery } from "../../../store/services/StoneTypesCountPlotX.services"
import Cookies from "js-cookie"
import { Box } from "@mui/material"
import { useGetStoneTypesTotalSellingPriceQuery } from "../../../store/services/stonetypes_totalsellingprice_plot.services"

interface GraphData {
  name: string
  TypeCount: number
}

export const TypesTotalSellingPrice = () => {
    const { data, isError, isLoading, isSuccess } = useGetStoneTypesTotalSellingPriceQuery(
      Cookies.get("access_token")
    )
  
    const [grapghData, setGraphData] = useState<GraphData[]>()
    useEffect(() => {
      if (data) {
        setGraphData(data.Data)
      }
    }, [data])
    return (
      <Box sx={{ height: "100%" }}>
        <BarChart
          width={700}
          height={500}
          data={grapghData}
          margin={{
            left: 60,
          }}
          layout='vertical'
        >
          <XAxis type='number' />
          <YAxis dataKey='stonetypes' type='category' />
          {/* <YAxis /> */}
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey='avg_selling_price' fill='#8884d8' />
        </BarChart>
      </Box>
    )
  }
  