import { configureStore } from "@reduxjs/toolkit"
import { signupApi } from "./services/signup.services"
import { signinApi } from "./services/siginin.services"
import { stoneTypeCountApi } from "./services/StoneTypesCountPlotX.services"
import { stoneOriginCountApi } from "./services/StoneOriginCountPlotY.services"
import { stoneShapeCountApi } from "./services/StoneShapesCountPlotX.services"
import { getStoneWeightBinsCountApi } from "./services/StoneWeightBinsCountPlotX.services"
import { stoneTotalSellingPriceBinsCountApi } from "./services/stoneTotalSellingPriceBinsCountPlot.services"
import { stoneTypesTotalSellingPriceApi } from "./services/stonetypes_totalsellingprice_plot.services"
import { stoneTypesWeightPriceApi } from "./services/stonetypes_weights_plot.services"
import { stoneTotalSellingPriceWeightsApi } from "./services/stone_totalsellingprice_weights.services"
import { totalSellingPriceAndStoneTypeTreatmentApi } from "./services/Totalsellingprice_stonetypetreatment_plot.services"
import { stoneTypeTreatmentCountApi } from "./services/stonetypetreatment_count_plot.services"
import { monthlySaleCountApi } from "./services/Monthly_sales_count_plot"
import { yearlySaleCountApi } from "./services/Yearly_sales_count_plot"
import { stonecutTotalSellingPrice } from "./services/Stonecut_totalSellingPrice"
import { originTotalSellingPriceApi } from "./services/Origin_TotalsellingPrice"

export const store = configureStore({
  reducer: {
    [signupApi.reducerPath]: signupApi.reducer,
    [signinApi.reducerPath]: signinApi.reducer,
    [stoneTypeCountApi.reducerPath]: stoneTypeCountApi.reducer,
    [stoneOriginCountApi.reducerPath]: stoneOriginCountApi.reducer,
    [stoneShapeCountApi.reducerPath]: stoneShapeCountApi.reducer,
    [getStoneWeightBinsCountApi.reducerPath]:
      getStoneWeightBinsCountApi.reducer,
    [stoneTotalSellingPriceBinsCountApi.reducerPath]:
      stoneTotalSellingPriceBinsCountApi.reducer,
    [stoneTypesTotalSellingPriceApi.reducerPath]:
      stoneTypesTotalSellingPriceApi.reducer,
    [stoneTypesWeightPriceApi.reducerPath]: stoneTypesWeightPriceApi.reducer,
    [stoneTotalSellingPriceWeightsApi.reducerPath]:
      stoneTotalSellingPriceWeightsApi.reducer,
    [totalSellingPriceAndStoneTypeTreatmentApi.reducerPath]:
      totalSellingPriceAndStoneTypeTreatmentApi.reducer,
    [stoneTypeTreatmentCountApi.reducerPath]:
      stoneTypeTreatmentCountApi.reducer,
    [monthlySaleCountApi.reducerPath]: monthlySaleCountApi.reducer,
    [yearlySaleCountApi.reducerPath]: yearlySaleCountApi.reducer,
    [stonecutTotalSellingPrice.reducerPath]: stonecutTotalSellingPrice.reducer,
    [originTotalSellingPriceApi.reducerPath]:
      originTotalSellingPriceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      signupApi.middleware,
      signinApi.middleware,
      stoneTypeCountApi.middleware,
      stoneOriginCountApi.middleware,
      stoneShapeCountApi.middleware,
      getStoneWeightBinsCountApi.middleware,
      stoneTotalSellingPriceBinsCountApi.middleware,
      stoneTypesTotalSellingPriceApi.middleware,
      stoneTypesWeightPriceApi.middleware,
      stoneTotalSellingPriceWeightsApi.middleware,
      totalSellingPriceAndStoneTypeTreatmentApi.middleware,
      stoneTypeTreatmentCountApi.middleware,
      monthlySaleCountApi.middleware,
      yearlySaleCountApi.middleware,
      stonecutTotalSellingPrice.middleware,
      originTotalSellingPriceApi.middleware
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
