import {
  Snackbar,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  TextField,
  Avatar,
  FormHelperText,
  Box,
  Link,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { useSignupUserMutation } from "../../store/services/signup.services"

import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom"
const gray = "rgb(166, 166, 166)"
const oxfordBlue = "#14213D"
const Signup = () => {
  const navigate = useNavigate()
  const [fullname, setFullname] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [fullNameError, setFullNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [signupUser, { isLoading, isError, error, isSuccess }] =
    useSignupUserMutation()
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // validate input fields
    let isValid = true
    if (!fullname) {
      setFullNameError("Full name is required")
      isValid = false
    } else {
      setFullNameError("")
    }
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Email is invalid")
      isValid = false
    } else {
      setEmailError("")
    }
    if (
      !password
      //  ||
      // !/^(?=.*[A-Z])[^\d]*$/.test(password)
    ) {
      setPasswordError("Password is invalid")
      isValid = false
    } else {
      setPasswordError("")
    }

    if (isValid) {
      const formData = new FormData()
      formData.append("fullname", fullname)
      formData.append("email", email)
      formData.append("password", password)
      signupUser(formData)
    }
  }
  useEffect(() => {
    if (isSuccess) {
      navigate("/signin")
    }
  }, [isSuccess])
  useEffect(() => {
    const token = Cookies.get("access_token")
    if (token) {
      navigate("/")
    }
  }, [])
  return (
    <>
      <Box
        sx={{
          display: { md: "flex" },
          justifyContent: { md: "center" },
          alignItems: { md: "center" },
          height: { md: "100vh" },
        }}
      >
        <Box
          sx={{
            display: { md: "flex" },
            borderRadius: { md: "5px" },
            height: { md: "600px" },
            width: { md: "1000px" },
            bgcolor: { md: oxfordBlue },
          }}
        >
          <Box sx={{ flex: { md: "1 1 auto" } }}>
            <Box sx={{ mt: { md: "215px" } }}>
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  textAlign: "center",
                  color: gray,
                  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  fontFamily: "futuraFixed",
                  display: { xs: "none", md: "block" },
                }}
              >
                Gemcloud Analytics
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              background: { md: "#FFFFFF" },
              borderBottomLeftRadius: {
                lg: 0,
                md: "12px",
                sm: "12px",
                xs: "12px",
              },
              height: { md: "568px" },
              width: { md: "496px" },
              borderRadius: { md: "3px" },
              my: { md: "auto" },
              mr: { md: "16px" },
              px: { xs: "20px", md: "0px" },
            }}
          >
            <Box
              sx={{
                mx: { md: "65px" },
                marginTop: { xs: "109px", md: "15%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: oxfordBlue }}>
                  <LockOutlinedIcon />
                </Avatar>
              </Box>

              <Typography
                sx={{
                  fontSize: "30px",
                  textAlign: "center",
                  fontWeight: 500,
                  lineHeight: "29px",
                  color: oxfordBlue,
                  mt: "24px",
                  fontFamily: "futuraMedium",
                }}
              >
                Signup
              </Typography>

              {/* <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  textAlign: "center",
                  color: "rgba(20, 33, 61, 0.4)",
                  mt: "16px",
                  fontFamily: "futuraBook",
                }}
              >
               Signup details
              </Typography> */}

              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                }}
              >
                <Box component='form' onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label='Full Name'
                    value={fullname}
                    onChange={(event) => setFullname(event.target.value)}
                    error={!!fullNameError}
                    helperText={fullNameError}
                    sx={{ height: "53px" }}
                  />
                  <TextField
                    fullWidth
                    label='Email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    error={!!emailError}
                    helperText={emailError}
                    sx={{ mt: "1rem", height: "53px" }}
                  />

                  <FormControl sx={{ mt: "1rem" }} fullWidth variant='outlined'>
                    <InputLabel
                      sx={{ lineHeight: "1.2rem", fontSize: "0.98rem" }}
                      htmlFor='outlined-adornment-password'
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      sx={{ height: "53px" }}
                      label='Password'
                      id='outlined-adornment-password'
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      error={!!passwordError}
                      // helperText={passwordError}

                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {passwordError && (
                      <FormHelperText error={true}>
                        {passwordError}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    type='submit'
                    sx={{ mt: "1.5rem", bgcolor: oxfordBlue }}
                  >
                    {isLoading ? "loading" : "Sign in"}
                  </Button>
                </Box>
              </Box>
            </Box>

            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 400,
                textAlign: "center",
                color: oxfordBlue,
                //   alignSelf:"end"
                mt: { xs: "229px", md: "80px" },
                fontFamily: "futuraBook",
              }}
            >
              Already have an accout?
              <Link href='/signin'>Signin</Link>
            </Typography>
          </Box>
        </Box>
        {/* <Snackbar
          open={alertOpen}
          autoHideDuration={2500}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity='error'
            sx={{ width: "100%" }}
            onClose={handleAlertClose}
          >
            {error}
          </Alert>
        </Snackbar> */}
      </Box>
    </>
  )
}

export default Signup
