import React, { useEffect, useState } from "react"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import Cookies from "js-cookie"
import { Box } from "@mui/material"
import { useGetOriginTotalSellingPriceQuery } from "../../../store/services/Origin_TotalsellingPrice"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import CircularProgress from "@mui/material/CircularProgress"
interface GraphData {
  avg_selling_price: number
  country: string
}

export const OriginTotalSellingPrice = () => {
  const { data, isError, isLoading, isSuccess } =
    useGetOriginTotalSellingPriceQuery(Cookies.get("access_token"))

  useEffect(() => {
    if (isSuccess && data) {
      const root = am5.Root.new("chartdiv")
      root.setThemes([am5themes_Animated.new(root)])

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          layout: root.verticalLayout,
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          pinchZoomX: true,
        })
      )
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      )

      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: "country",
        })
      )
      xAxis.data.setAll(data.Data)

      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Originv total selling",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "avg_selling_price",
          categoryXField: "country",
          tooltip: am5.Tooltip.new(root, {
            labelText: "{avg_selling_price}",
          }),
        })
      )
      series.data.setAll(data.Data)
      series.columns.template.set("fill", am5.color("#7391D0")) // Set to red color
      series.columns.template.set("stroke", am5.color("#7391D0")) // Set to red color

      const legend = chart.children.push(am5.Legend.new(root, {}))
      legend.data.setAll(chart.series.values)

      chart.set("cursor", am5xy.XYCursor.new(root, {}))

      return () => {
        if (root) {
          root.dispose()
        }
      }
    }
  }, [data, isSuccess])
  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}>
      <CircularProgress disableShrink />
    </Box>
  ) : (
    <div id='chartdiv' style={{ width: "100%", height: "500px" }}></div>
  )
  // return (
  //   <Box sx={{ height: "100%" }}>
  //     <BarChart
  //       width={700}
  //       height={500}
  //       data={grapghData}
  //       margin={{
  //         left: 60,
  //       }}
  //       layout='vertical'
  //     >
  //       <XAxis type='number' />
  //       <YAxis dataKey='country' type='category' />
  //       {/* <YAxis /> */}
  //       <Tooltip />
  //       {/* <Legend /> */}
  //       <Bar dataKey='avg_selling_price' fill='#8884d8' />
  //     </BarChart>
  //   </Box>
  // )
}
