import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import EqualizerIcon from "@mui/icons-material/Equalizer"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { useLocation, useNavigate } from "react-router-dom"
import { Button } from "@mui/material"
import Cookies from "js-cookie"
const graphName = [
  {
    name: "Type count",
    path: "/",
  },
  {
    name: "Origin count",
    path: "/Origin-count",
  },
  {
    name: "Shape count",
    path: "/Shape-count",
  },
  {
    name: "Stone weight bins count",
    path: "/Stone-weight-bins-count",
  },
  {
    name: "Stone total selling price bins count",
    path: "/Stone-total-selling-price-bins-count",
  },
  {
    name: "Stone Type price Total selling price",
    path: "/Stone-price-total-selling-price",
  },
  {
    name: "Stone type and stone weight",
    path: "/Stone-Type-stone-weight",
  },
  {
    name: "Stone total selling price and weight",
    path: "/Stone-total-selling-price-and-weight",
  },
  {
    name: "Total selling price stone type treatment",
    path: "/Total-selling-price-stone-type-treatment",
  },
  {
    name: "Stone type treatment count",
    path: "/Stone-type-treatment-count",
  },
  {
    name: "Stonecut total selling price",
    path: "/Stonecut-total-selling-price",
  },
  {
    name: "Origin total selling price",
    path: "/Origin-total-selling-price",
  },
  {
    name: "Monthly stone selling count",
    path: "/Monthly-stone-selling-count",
  },
  // {
  //   name: "Yearly type treatment count",
  //   path: "/Yearly-type-treament-count",
  // },
]
const drawerWidth = 240

interface Props {
  window?: () => Window
  children: React.ReactNode
}

export default function Layout(props: Props) {
  const { window, children } = props
  const navigate = useNavigate()
  let location = useLocation()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [show, setShow] = React.useState(true)
  const [active, setActive] = React.useState("")
  const handleNavigate = (path: string, menuItem: string) => {
    setActive(menuItem)
    navigate(path)
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const token = Cookies.get("access_token")
  React.useEffect(() => {
    const storedActive = localStorage.getItem("active")
    if (storedActive) {
      setActive(storedActive)
    }
  }, [])

  React.useEffect(() => {
    localStorage.setItem("active", active)
  }, [active])
  

  const drawer = (
    <div>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "1.3rem",
          fontWeight: 600,
          mt: "1.2rem",
          color: "#3D5B9C",
        }}
      >
        Gemcloud Analytics
      </Typography>
      {/* <Toolbar /> */}
      <Box
        sx={{
          mt: "1.8rem",
        }}
      >
        <Box
          sx={{
            bgcolor: "#3D5B9C",
            borderTopRightRadius: "1rem",
            borderBottomRightRadius: "1rem",
            mr: "0.8rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mx: "1.5rem",
            }}
          >
            <EqualizerIcon sx={{ color: "white" }} />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                textTransform: "uppercase",
                fontFamily: "mulish-regular",
                color: "white",
              }}
            >
              {/* {label} */}
              Analytics
            </Typography>

            <IconButton onClick={() => setShow(!show)}>
              {show ? (
                <ExpandMoreIcon sx={{ color: "white" }} />
              ) : (
                <ExpandLessIcon sx={{ color: "white" }} />
              )}
            </IconButton>
          </Box>
        </Box>
        {show && (
          <Box sx={{ width: "200px", mx: "auto" }}>
            {graphName &&
              graphName.map((stone, index) => {
                // const stoneSelected = value.includes(stone.id)

                return (
                  <Box
                    onClick={() => handleNavigate(stone.path, stone.name)}
                    key={index}
                    sx={{
                      whiteSpace: "break-spaces",
                      fontFamily: "mulish-regular",
                      minWidth: "10rem",
                      textOverflow: "ellipsis",
                      lineHeightL: "1rem",
                      mt: "0.8rem",
                      ml: "0.6rem",
                      cursor: "pointer",
                      // bgcolor:"red"
                      fontWeight: stone.name === active ? 600 : 500,
                      color: stone.name === active ? "#3D5B9C" : "#778494",
                    }}
                  >
                    {stone.name}
                  </Box>
                )
              })}
          </Box>
        )}
      </Box>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#3D5B9C",
          display:
            location.pathname === "/signin" || location.pathname === "/signup"
              ? "none"
              : "block",
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant='h6' noWrap component='div'>
              {location.pathname === "/"
                ? "Type count"
                : location.pathname.replace(/^\//, "").replace(/-/g, " ")}
            </Typography>
            {token ? (
              <Button
                onClick={() => {
                  Cookies.remove("access_token")
                  navigate("/signin")
                }}
                sx={{ color: "white" }}
              >
                Logout
              </Button>
            ) : (
              <Button
                onClick={() => navigate("/signin")}
                sx={{ color: "white" }}
              >
                login
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          display: location.pathname === "/signin" ? "none" : "block",
        }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: location.pathname === "/signin" ? 0 : 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar
          sx={{ display: location.pathname === "/signin" ? "none" : "block" }}
        />
        {children}
      </Box>
    </Box>
  )
}
