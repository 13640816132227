import React from "react"
import logo from "./logo.svg"
import "./App.css"
import { Route, Routes } from "react-router-dom"
import Signin from "./Auth/signin/signin"
import Signup from "./Auth/signup/Signup"
import ProtectedRoute from "./routes/ProtectedRoute"
import { HomePage } from "./components/HomePage"
import { Box, Typography } from "@mui/material"
import { TypeCount } from "./components/graphs/TypesCountX/TypesCountX"
import { OriginCount } from "./components/graphs/OriginCountY/OriginCountY"
import { ShapesCount } from "./components/graphs/ShapesCountX/ShapesCountX"
import { WeightBinsCount } from "./components/graphs/WeightBinsCountX/WeightBinsCountX"
import { TotalSellingPriceBinsCount } from "./components/graphs/TotalSellingPriceBinsCount/TotalSellingPriceBinsCount"
import { TypesTotalSellingPrice } from "./components/graphs/TypesTotalSellingPrice/TypesTotalSellingPrice"
import { TypesWeights } from "./components/graphs/TypesWeight/TypesWeights"
import { TotalSellingPriceWeight } from "./components/graphs/TotalSellingPriceWeight/TotalSellingPriceWeight"
import { TotalSellingPriceStoneTypeTreatment } from "./components/graphs/TotalSellingPriceStoneTypeTreatment/TotalSellingPriceStoneTypeTreatment"
import { TypeTreatmentCount } from "./components/graphs/TypeTreatmentCount/TypeTreatmentCount"
import { StonecutTotalSellingPrice } from "./components/graphs/StonecutTotalSellingPrice/StonecutTotalSellingPrice"
import { OriginTotalSellingPrice } from "./components/graphs/OriginTotalSellingPrice/OriginTotalSellingPrice"
import { MonthlySalesCount } from "./components/graphs/MonthlySalesCount/MonthlySalesCount"

function App() {
  return (
    <Routes>
      <Route path='/signin' element={<Signin />} />
      <Route path='/signup' element={<Signup />} />
      <Route element={<ProtectedRoute />}>
        <Route
          path='/'
          element={
            <GraphCard description='The graph below shows the sales information about the stone type sold the most which is Sapphire.'>
              <TypeCount />
            </GraphCard>
          }
        />
        <Route
          path='/Origin-count'
          element={
            <GraphCard description='The graph below shows the sales information about the stones from which origin were sold the most. '>
              <OriginCount />
            </GraphCard>
          }
        />
        <Route
          path='/Shape-count'
          element={
            <GraphCard
              description='The graph below shows the sales information about the shape of the
            stones. It is evident that oval shaped stones are sold the most.'
            >
              <ShapesCount />
            </GraphCard>
          }
        />
        <Route
          path='/Stone-weight-bins-count'
          element={
            <GraphCard description='The graph below shows the sales information about the weight of the stones. It is clear that the stones whose weight lies between 1 to 2 grams are top sold followed by 0.5 to 1.0.'>
              <WeightBinsCount />
            </GraphCard>
          }
        />
        <Route
          path='/Stone-total-selling-price-bins-count'
          element={
            <GraphCard description='The graph below shows the sales information about the weight of the stones in relation with the stone types.'>
              <TotalSellingPriceBinsCount />
            </GraphCard>
          }
        />
        <Route
          path='/Stone-type-price-total-selling-price'
          element={
            <GraphCard description='The graph below shows the sales information about the price range of the stones. Mostly sold stones price range between 100 to 200 dollars'>
              <TypesTotalSellingPrice />
            </GraphCard>
          }
        />
        <Route
          path='/Stone-Type-stone-weight'
          element={
            <GraphCard description='The graph below shows the sales information about the price of the stones in relation with the shape cut of the stone .'>
              <TypesWeights />
            </GraphCard>
          }
        />
        <Route
          path='/Stone-total-selling-price-and-weight'
          element={
            <GraphCard description='The graph below shows the sales information about the price range of the stones. Mostly sold stones price range between 100 to 200 dollars'>
              <TotalSellingPriceWeight />
            </GraphCard>
          }
        />
        <Route
          path='/Total-selling-price-stone-type-treatment'
          element={
            <GraphCard description='The graph below shows the sales information about the price of the stones in relation with the shape cut of the stone .'>
              <TotalSellingPriceStoneTypeTreatment />
            </GraphCard>
          }
        />
        <Route
          path='/Stone-type-treatment-count'
          element={
            <GraphCard description='The graph below shows the sales information about the price range of the stones. Mostly sold stones price range between 100 to 200 dollars'>
              <TypeTreatmentCount />
            </GraphCard>
          }
        />
        <Route
          path='/Stonecut-total-selling-price'
          element={
            <GraphCard description='The graph below shows the total selling price of stone cuts'>
              <StonecutTotalSellingPrice />
            </GraphCard>
          }
        />
        <Route
          path='/Origin-total-selling-price'
          element={
            <GraphCard description='The graph below shows the total selling price of stone in specific origin'>
              <OriginTotalSellingPrice />
            </GraphCard>
          }
        />
        <Route
          path='/Monthly-stone-selling-count'
          element={
            <GraphCard description='The graph below shows the total selling price of stone on monthly bases'>
              <MonthlySalesCount />
            </GraphCard>
          }
        />
        <Route
          path='/Yearly-type-treament-count'
          element={
            <GraphCard description='The graph below shows the total selling price of stone on yearly bases'>
              {/* <Yearly_sales_count_plot />  */}
            </GraphCard>
          }
        />
      </Route>
    </Routes>
  )
}

export default App
interface Props {
  description: string
  children: React.ReactNode
}

export const GraphCard = ({ description, children }: Props) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}>
      <Box
        sx={{
          bgcolor: "white",
          px: "2rem",
          borderRadius: "0.5rem",
          // ml: "2rem",
          mb: "2rem",
        }}
      >
        {/* <Typography sx={{ fontSize: "1.4rem", fontWeight: 600, pt: "1rem" }}>
        {title}
      </Typography> */}
        <Typography
          sx={{
            fontWeight: 500,
            pt: "0.5rem",
            opacity: 0.4,
            width: 700,
            fontSize: "0.9rem",
          }}
        >
          {description}
        </Typography>
        <Box sx={{ my: "1rem" }}>{children}</Box>
      </Box>
    </Box>
  )
}
